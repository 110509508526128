<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">
        <b-card

        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     -->
              <!--对账日期-->
              <b-col md="4" cols="12">
                <xy-input-button label="到账日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['verify_date'])"
                                 :params="['verify_date']"
                                 :value="state.condition.verify_date"
                >
                </xy-input-button>
              </b-col>
              <!--付款单位-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款单位:"
                    label-for="pay_company_name"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.pay_company_name"
                      name="pay_company_name"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="付款单位"
                  />
                </b-form-group>
              </b-col>
              <!--店铺团队-->
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="店铺团队"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'])"
                    :params="['store_name','store_id']"
                    :value="state.condition.store_name"
                    :companyId="companyId"
                    modalName="店铺"
                    placeholder="点击选择店铺 "
                >
                </xy-input-button>
              </b-col>


              <!--添加日期-->
              <b-col md="4" cols="12">
                <xy-input-button label="添加日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['add_time'])"
                                 :params="['add_time']"
                                 :value="state.condition.add_time"
                >
                </xy-input-button>
              </b-col>
              <!--收款金额-->
              <b-col md="8" cols="12">
                <form-input-range
                    v-model="state.condition.money"
                    startPlaceholder="收款金额起始值"
                    endPlaceholder="收款金额结束值"
                    type="number"
                >
                </form-input-range>
              </b-col>

              <!--认领状态-->
              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="认领状态:"
                    label-for="declare_status"
                    label-size="sm"
                >
                  <v-select
                      id="declare_status"
                      :options="getCodeOptions('declare_status')"
                      :clearable="true"
                      v-model="state.condition.status"
                      :reduce="item => item.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <!--流水序号-->
              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="流水序号:"
                    label-for="finance_id"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.finance_id"
                      name="finance_id"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="流水序号"
                  />
                </b-form-group>
              </b-col>

              <!--附言-->
              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="附言:"
                    label-for="remark"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.remark"
                      name="trade_no"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="附言"
                  />
                </b-form-group>
              </b-col>

              <!--流水号-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="流水号:"
                    label-for="trade_no"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.trade_no"
                      name="trade_no"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="流水号"
                  />
                </b-form-group>
              </b-col>

              <!--收款单位-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款单位:"
                    label-for="receive_company_name"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.receive_company_name"
                      name="receive_company_name"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="收款单位"
                  />
                </b-form-group>
              </b-col>

              <!--收款账号-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款账号:"
                    label-for="receive_account_no"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.receive_account_no"
                      name="receive_account_no"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="收款账号"
                  />
                </b-form-group>
              </b-col>

              <!--收款方式-->
              <b-col
                  cols="12"
                  md="4"
                  class="mt-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款方式:"
                    label-for="receive_method"
                    label-size="sm"
                >
                  <v-select
                      id="receive_method"
                      :options="getCodeOptions('statement_pay_method')"
                      :clearable="true"
                      v-model="state.condition.receive_method"
                      :reduce="item => item.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>


              <!--操作按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-show="buttonShow"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-b-modal.modal-export-finance
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-show="buttonShow"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="deleteData()"
                >
                  <span class="align-right">删除</span>
                </b-button>

                <b-button
                    v-show="buttonShow"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning"
                    @click="allotDataShowModal()"
                >
                  <span class="align-right">分配</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="receiveData()"
                >
                  <span class="align-right">认领</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportDataTT()"
                >
                  <span class="align-right">导出</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportDataT()"
                >
                  <span class="align-right">流水收款导出</span>
                </b-button>

                <b-button
                    v-show="verificationShow"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    @click="toVerification()"
                >
                  <span class="align-right">代收核销</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  style="margin-top: 5px"
                  variant="primary"
                  show
              >
                <div class="alert-body">
                <span><strong>已选中:{{
                    checked.length
                  }}条  可认领收款:{{
                    checked.reduce((sum, e) => sum += Math.abs(isNumber(e.receive_amount)) > 0 ? isNumber(e.ext.can_receive_amount) : 0, 0)
                  }}元,可认领退款:{{
                    checked.reduce((sum, e) => sum += Math.abs(isNumber(e.refund_amount)) > 0 ? isNumber(e.ext.can_receive_amount) : 0, 0)
                  }}元</strong> </span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="tableArray"
      >

        <!-- Columns -->
        <template v-slot:head(select)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(select)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!--对账日期-->
        <template #cell(verify_date)="data">
          {{ toDate(data.item.verify_date) }}
        </template>


        <!--交易时间-->
        <template #cell(trade_date)="data">
          {{ toDate(data.item.trade_date) }}
        </template>

        <!--收款方式-->
        <template #cell(receive_method)="data">
          {{ getCodeLabel('statement_pay_method', data.item.receive_method) }}
        </template>


        <!--可认领金额-->
        <template #cell(can_receive_amount)="data">
          <div v-if="isNumber(data.item.refund_amount)>0">
            <span style="color: red">{{ isNumber(data.item.ext.can_receive_amount) }}</span>
          </div>
          <div v-else>
            {{ isNumber(data.item.ext.can_receive_amount) }}
          </div>

        </template>

        <!--审核中金额-->
        <template #cell(check_amount)="data">
          <div v-if="isNumber(data.item.refund_amount)>0">
            <span style="color: red">{{ isNumber(data.item.ext.check_amount) }}</span>
          </div>
          <div v-else>
            {{ isNumber(data.item.ext.check_amount) }}
          </div>
        </template>

        <!--认领状态-->
        <template #cell(status)="data">
          {{ getCodeLabel('declare_status', data.item.status) }}
        </template>

        <!--摘要-->
        <template #cell(remark)="data">
          <div :id="`remark-${data.item.id}`">
                     <span v-if="data.item.remark">
                       {{ data.item.remark.substring(0, 6) }}
                     <span v-if="data.item.remark.length>6">...</span>
                     </span>
          </div>
          <b-tooltip :target="`remark-${data.item.id}`" placement="top">
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <!--添加时间-->
        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <!--退款金额-->
        <template #cell(refund_amount)="data">
          <span style="color: red">{{ data.item.refund_amount }}</span>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="cancelRelation(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">查看关联</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!--导入modal-->
    <b-modal
        id="modal-export-finance"
        ref="modelExportFinance"
        hide-footer
        centered
        size="xl"
        title="导入数据"
    >
      <b-col md="12">
        <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
        >
          <attachment-upload :theme="'files'"
                             :object_type="'excel'"
                             @change="onUploaded"
          />
        </b-form-group>
      </b-col>
      <b-col
          cols="12"
          class="mt-50"
      >
        <b-button
            variant="primary"
            class="mr-1"
            @click="exportData"
        >
          保存
        </b-button>
      </b-col>
    </b-modal>

    <!--分配modal-->
    <b-modal
        id="modal-allot-finance"
        ref="modalAllotFinance"
        hide-footer
        centered
        size="sm"
        title="分配归属"
        ok-only
        ok-title="关闭"
    >
      <!--认领状态-->
      <b-col
          cols="12"
          md="12"
      >
        <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="认领状态:"
            label-for="declare_status_modal"
            label-size="sm"

        >
          <v-select
              id="declare_status_modal"
              :options="getCodeOptions('declare_status')"
              :clearable="true"
              v-model="statusModal"
              :reduce="item => item.value"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <!--店铺团队-->
      <b-col
          cols="12"
          md="12"
          class="mb-2"
      >
        <modal-select
            label="店铺团队"
            type="input"
            v-on:change="fromChildrenModal($event,['storeNameModal','storeIdModal'])"
            :params="['storeNameModal','storeIdModal']"
            :value="storeNameModal"
            :store_ids="storeIdArr"
            modalName="店铺"
            placeholder="点击选择店铺 "
        >
        </modal-select>
      </b-col>
      <!--收款账号-->
      <!--      <b-col-->
      <!--              cols="12"-->
      <!--              md="12"-->
      <!--              class="mb-2"-->
      <!--      >-->
      <!--        <b-form-group-->
      <!--                label-cols="3"-->
      <!--                label-cols-lg="3"-->
      <!--                label="收款账号"-->
      <!--                label-for="accountNo"-->
      <!--                label-size="sm"-->
      <!--                class="mb-1 "-->
      <!--        >-->

      <!--            <v-select-->
      <!--                    id="accountNo"-->
      <!--                    :options="accountNoArr"-->
      <!--                    :clearable="true"-->
      <!--                    class="select-size-sm"-->
      <!--                    v-model="accountNo"-->
      <!--                    @input="changeSelect('account',$event)"-->
      <!--                    placeholder="请选择收款账号"-->
      <!--            />-->
      <!--        </b-form-group>-->
      <!--      </b-col>-->
      <b-col
          cols="12"
          class="mt-50"
      >
        <b-button
            variant="primary"
            class="mr-1"
            @click="allotData"
        >
          保存
        </b-button>
      </b-col>
    </b-modal>

    <b-modal
        id="modal-statement-receive"
        centered
        hide-footer
        size="lg"
        title="选择需要取消关联的单据"
        ref="modal-statement-receive"
    >
      <b-table
          v-if="isEmpty(proxyVerificationList)"
          ref="refStatementReceiveListTable"
          class="position-relative"
          :items="statementReceiveList"
          responsive
          hover
          small
          :fields="fields"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="success"
          select-mode="single"
          @row-selected="onRowSelected"
      >

        <template #cell(no)="data">
          <a target="_blank" :href="toURL(data.item)" style="color: deepskyblue">
            {{ data.item.ext.statementReceive.receive_no }}
          </a>
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('statement_receive_status', data.item.ext.statementReceive.status)}`"
          >
            {{ getCodeLabel("statement_receive_status", data.item.ext.statementReceive.status) }}
          </b-badge>
        </template>


<!--        <template #cell(actions)="data">-->
<!--          <b-dropdown-->
<!--                  variant="link"-->
<!--                  no-caret-->
<!--          >-->
<!--            <template #button-content>-->
<!--              <feather-icon-->
<!--                      icon="MoreVerticalIcon"-->
<!--                      size="16"-->
<!--                      class="align-middle text-body"-->
<!--              />-->
<!--            </template>-->

<!--            <b-dropdown-item @click="cancelRelationItem(data.item)">-->
<!--              <feather-icon icon="EditIcon"/>-->
<!--              <span class="align-middle ml-50">取消关联</span>-->
<!--            </b-dropdown-item>-->
<!--          </b-dropdown>-->
<!--        </template>-->

        <template #cell(actions)="data">
              <b-button  v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" @click="cancelRelationItem(data.item)" >取消关联</b-button>
        </template>


      </b-table>

      <b-table
          v-if="!isEmpty(proxyVerificationList)"
          ref="refProxyVerificationListTable"
          class="position-relative"
          :items="proxyVerificationList"
          responsive
          hover
          small
          :fields="fields"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(no)="data">
          <b-link
              :to="{ name: 'apps-statementproxyverification-edit', query: { id: data.item.id ,ids:data.item.ext.finance_ids.split(','),store_id:data.item.store_id,store_name:data.item.store_name} }"
              target="_blank"
              style="color: deepskyblue"
          >
            {{ data.item.ve_no }}
          </b-link>
        </template>

        <template #cell(receive_total)="data">
          {{data.item.amount_verification}}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('verification_status', data.item.status)}`"
          >
            {{ getCodeLabel('verification_status', data.item.status) }}
          </b-badge>
        </template>
<!--        <template #cell(actions)="data">
          <b-button  v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" @click="cancelRelationItem(data.item)" >取消关联</b-button>
        </template>-->


      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, onMounted, reactive} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isNumber,
  isEmpty, getAbs
} from '@core/utils/filter'
import financeresourceUseList from './financeresourceUseList'
import financeresourceStore from './financeresourceStore'
import {useToast} from "vue-toastification/composition";
import XyInputButton from "@/views/components/xy/XyInputButton";
import ModalSelect from "@/views/components/modal/ModalSelect";
import FormInputRange from "@/views/components/xy/FormInputRange";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {getUserData} from "@/auth/utils";
import Ripple from "vue-ripple-directive";
import statementreceiveStore from "@/views/apps/statementreceive/statementreceiveStore";
import financeresourcerelationStore from "@/views/apps/financeresourcerelation/financeresourcerelationStore";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    FormInputRange,
    AttachmentUpload,
    ModalSelect,
  },
  directives: {
    Ripple,
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    searchByCondition() {
      this.reload()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('financeresource/updateCondition', this.state.condition)
      this.reload()
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('financeresource/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    // 点击全选按钮
    toggleAll(checked) {
      const array = []
      for (let i = 0; i < this.checked.length; i++) {
        for (let j = 0; j < this.tableArray.length; j++) {
          if (this.checked.length > 0 && this.checked[i].entity_id == this.tableArray[j].entity_id) {
            array.push(i)
          }
        }
      }
      for (let i = array.length - 1; i >= 0; i--) {
        this.checked.splice(array[i], 1);
      }
      this.tableArray.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      console.log(1, this.checked)
      this.$forceUpdate()

    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = -1;
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].entity_id == id.entity_id) {
          index = i
        }
      }
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
    },
  },
  data() {
    return {
      excelId: '', //导入excelId
      regDataPass: false, //导入数据是否通过验证
      totalMemo: "", // 导入汇总信息
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      checked: [],
      tableArray: [],
      buttonShow: false,
      verificationShow: false,
      statusModal: '', //认领按钮选择的状态
      storeNameModal: '', //选择的团队名称
      storeIdModal: '', //选择的团队id
      selected: [],
      statementReceiveList: [],
      storeIdArr: "",
      accountNo: '',
      accountNoArr: [],
      companyIdModal: '',
      accountId: '',
      companyId: 90,
      fields: [
        {key: 'no', label: '编号'},
        {key: 'receive_total', label: '收款金额'},
        {key: 'status', label: '状态'},
        {key: 'actions', label: '操作'},
      ],
      proxyVerificationList: [],
    }
  },
  mounted() {
    this.isCheckedObj = {};
    this.tableArray.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    });
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        for (let i = 0; i < this.checked.length; i++) {
          for (let j = 0; j < this.tableArray.length; j++) {
            if (this.checked[i].entity_id == this.tableArray[j].entity_id) {
              show += 1;
            }
          }
        }

        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (this.tableArray.length === show) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    tableArray: {
      handler(newVal, oldVal) {
        let show = 0;
        for (let i = 0; i < this.checked.length; i++) {
          for (let j = 0; j < this.tableArray.length; j++) {
            if (this.checked[i].entity_id == this.tableArray[j].entity_id) {
              show += 1;
            }
          }
        }
        if (this.tableArray.length === show) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
      }
    },
    // selected: {
    //   handler() {
    //     this.checked = []
    //     this.tableArray = []
    //     this.allchecked = false
    //     this.indeterminate = false
    //     this.isCheckedObj = {}
    //     this.reload()
    //   }
    // }
  },
  setup() {
    // Register module
    if (!store.hasModule('financeresource')) store.registerModule('financeresource', financeresourceStore)
    if (!store.hasModule('statementreceive')) store.registerModule('statementreceive', statementreceiveStore)
    if (!store.hasModule('financeresourcerelation')) store.registerModule('financeresourcerelation', financeresourcerelationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financeresource')) store.unregisterModule('financeresource')
      if (store.hasModule('statementreceive')) store.unregisterModule('statementreceive')
      if (store.hasModule('financeresourcerelation')) store.unregisterModule('financeresourcerelation')
    })

    const toast = useToast()

    onMounted(() => {
      state.condition = store.getters['financeresource/getCondition']
    })

    const state = reactive({
      condition: {},
    })
    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const resetChecked = function(){
      this.allchecked= false
      this.indeterminate= false
      this.isCheckedObj= {}
      this.checked= []
      this.tableArray= []
    }

    const fromChildrenModal = function (params, modal) {
      if (modal.length > 1) {
        this.storeNameModal = params == null ? null : params[modal[0]]
        this.storeIdModal = params == null ? null : params[modal[1]]
        axios.post('/api/financeresourcerelation/searchAccountByStoreIdAndCompanyId', {
          storeId: this.storeIdModal,
          companyId: this.companyIdModal,
        }).then(res => {
          if (res.data.data.length > 0) {
            let list = res.data.data
            this.accountNoArr = []
            for (let i = 0; i < list.length; i++) {
              this.accountNoArr.push({label: list[i].account_no, value: list[i].account_id})
            }
          }
        })
      }
      this.$forceUpdate()
    }

    const exportData = function () {
      if (isNumber(this.excelId) > 0 && this.regDataPass) {
        // 导入数据
        if (confirm(this.totalMemo)) {
          store.dispatch('financeresource/exportExcelData', {excelId: this.excelId})
              .then(response => {
                const data = response.data.data
                if (response.data.code == 0) {
                  toast.success("导入成功!")
                  this.reload()
                  this.$refs['modelExportFinance'].toggle('#toggle-btn')
                } else {
                  toast.error(data)
                }
              })
        }
      }
    }

    const deleteData = function () {
      let data = this.checked;
      let ids = []
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let status = data[i].status
          let checkAmount = isNumber(data[i].ext.check_amount)
          if (status === 4 || status === 5 || checkAmount != 0) {
            toast.error("删除数据中存在部分认领/已完成/审核金额的数据,删除失败,请重新选择!")
            return;
          } else {
            ids.push(data[i].finance_id)
          }
        }
      } else {
        toast.error("请勾选删除的数据!")
        return
      }
      if (ids.length > 0) {
        let id = ids.join(",")
        if (confirm("确定删除序号为：" + id + "数据?")) {
          store.dispatch('financeresource/deleteDataByIds', {ids: id})
              .then(response => {
                const data = response.data.data
                if (response.data.code == 0) {
                  toast.success("删除成功!")
                  this.reload()
                } else {
                  toast.error(data)
                }
              })
        }
      }
    }

    const allotData = function () {
      // if (!isEmpty(this.storeIdModal) && isEmpty(this.accountId)) {
      //   toast.error("请选择收款账号!")
      //   return
      // }
      if (isEmpty(this.storeIdModal) && isEmpty(this.accountId) && isEmpty(this.statusModal)) {
        toast.error("请选择更新内容!")
        return
      }
      let data = this.checked;
      let ids = []
      for (let i = 0; i < data.length; i++) {
        ids.push(data[i].finance_id)
      }
      if (ids.length > 0) {
        let id = ids.join(",")
        store.dispatch('financeresource/allotDataByIds', {
          ids: id,
          status: this.statusModal,
          storeId: this.storeIdModal,
          storeName: this.storeNameModal,
          // accountId: this.accountId,
          // accountNo: this.accountNo,
        })
            .then(response => {
              const data = response.data.data
              if (response.data.code == 0) {
                toast.success("分配成功!")
                this.reload()
                this.$refs['modalAllotFinance'].toggle('#toggle-btn')
              } else {
                toast.error(data)
              }
            })
      }
    }

    const receiveData = function () {
      let data = this.checked;
      let ids = []
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          ids.push(data[i].finance_id)
        }
      } else {
        toast.error("请勾选认领的数据!")
        return
      }
      if (ids.length > 0) {
        //判断是否有关联单据
        axios.post('api/statementproxyverificationitem/searchByIds', {ids: ids.toString()}).then(res => {
          if (res.data.data.length > 0) {
            toast.error('存在已核销的流水，不能认领')
          } else {
            store.dispatch('statementreceive/financeResourceCreateStatementReceive', {financeIds: ids.join(",")})
                .then(response => {
                  const data = response.data.data
                  if (response.data.code == 0) {
                    let routeData = this.$router.resolve({
                      name: 'apps-statementreceive-edit',
                      query: {id: data.receive_id, type: 2, readonlys: 0, srmReadonly: 1},
                    })
                    window.open(routeData.href, '_blank')
                    toast.success("生成成功!")
                  } else {
                    toast.error(data)
                  }
                  this.resetChecked()
                })
          }
        })
      }
    }

    const cancelRelation = function (params) {
      store.dispatch('statementreceive/searchByFinanceId', {financeId: params.finance_id})
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              this.statementReceiveList = data
              axios.post('api/statementreceive/searchVeByFinanceId', {financeId: params.finance_id}).then(res => {
                if(res.data.code == 0){
                  this.proxyVerificationList = res.data.data
                  this.$refs['modal-statement-receive'].show()
                }
              })
            } else {
              toast.error(data)
            }
          })
    }

    const onUploaded = async function (id, attachment, result) {
      this.excelId = result
      if (isNumber(this.excelId) > 0) {
        // 完成前期验证
        store.dispatch('financeresource/regExcelData', {excelId: this.excelId})
            .then(response => {
              const data = response.data.data
              if (response.data.code == 0) {
                //验证通过 可以导入
                this.regDataPass = true
                this.totalMemo = data
                toast.success("数据验证通过!")
              } else {
                //验证失败
                this.regDataPass = false
                this.$refs['modelExportFinance'].toggle('#toggle-btn')
                toast.error(data)
              }
            })
      }
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked.length = 0
      this.indeterminate = false
      this.allchecked = false
      this.statusModal = ''
      this.storeNameModal = ''
      this.storeIdModal = ''
      this.accountNoArr = []
      this.accountId = ''
      this.accountNo = ''
      refetchData()
    }


    const cancelRelationItem = function (data) {
      let financeId = data.finance_id
      if (confirm("是否取消关联选择的单据!")) {
        store.dispatch('statementreceive/copyStatementReceive', {methodId: data.method_id})
                .then(response => {
                  const data = response.data.data
                  if (response.data.code == 0) {
                    if (data === 'success') {
                      toast.success("取消成功!")
                      store.dispatch('statementreceive/searchByFinanceId', {financeId: financeId})
                              .then(response => {
                                const data = response.data.data
                                if (response.data.code == 0) {
                                  this.statementReceiveList = data
                                } else {
                                  toast.error(data)
                                }
                              })
                      this.forceUpdate()
                      refetchData()
                    } else {
                      let routeData = this.$router.resolve({
                        name: 'apps-statementreceive-edit',
                        query: {id: data.receive_id, type: 2, readonlys: 0, srmReadonly: 1},
                      })
                      window.open(routeData.href, '_blank')
                      toast.success("生成成功!")
                      refetchData()
                    }
                  } else {
                    toast.error(data)
                  }
                })
      }
    }


    const createStatementReceive = function () {
      if (this.selected.length == 0) {
        toast.error("请选择单据!")
        return;
      }
      if (confirm("是否取消关联选择的单据!")) {
                store.dispatch('statementreceive/copyStatementReceive', {methodId: this.selected[0].method_id})
                        .then(response => {
                          const data = response.data.data
                          if (response.data.code == 0) {
                            if (data === 'success') {
                              toast.success("取消成功!")
                              refetchData()
                            } else {
                              let routeData = this.$router.resolve({
                                name: 'apps-statementreceive-edit',
                                query: {id: data.receive_id, type: 2, readonlys: 0, srmReadonly: 1},
                              })
                              window.open(routeData.href, '_blank')
                              toast.success("生成成功!")
                              refetchData()
                            }
                          } else {
                            toast.error(data)
                          }
                        })
              }
    }

    const allotDataShowModal = function () {
      let data = this.checked;
      if (data.length > 0) {
        this.statusModal = '', //认领按钮选择的状态
            this.storeNameModal = '', //选择的团队名称
            this.storeIdModal = '', //选择的团队id
            this.storeIdArr = '',
            this.accountNo = ''
        this.accountId = ''
        this.companyIdModal = data[0].company_id
        let companyName = data[0].company_name
        for (let i = 0; i < data.length; i++) {
          let status = data[i].status
          let itemCompanyName = data[i].company_name
          let canAllot = data[i].ext.can_allot
          if (status === 4 || !canAllot) {
            toast.error("分配失败,请重新选择!")
            return
          } else if (itemCompanyName != companyName) {
            toast.error("分配数据必须是统一收款单位!")
            return
          }
        }
        //
        store.dispatch('financeresourcerelation/getListByCompanyId', {companyId: this.companyIdModal})
            .then(response => {
              const data = response.data.data
              if (response.data.code == 0) {
                if (data.length > 0) {
                  let ids = []
                  for (let i = 0; i < data.length; i++) {
                    ids.push(data[i].store_id)
                  }
                  this.storeIdArr = ids.join(",")
                }
                this.$forceUpdate()
                this.$refs['modalAllotFinance'].show()
              } else {
                toast.error("团队加载失败!")
                return
              }
            })

      } else {
        toast.error("请勾选分配的数据!")
        return
      }

    }

    // const changeSelect = function(key,event) {
    //   this.accountId = event.value
    //   this.accountNo = event.label
    // }

    const exportDataT = function () {
      const condition = {...store.getters['financeresource/getCondition']}
      const params = {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc' : '',
        moneyStart: condition.money === undefined ? '' : condition.money.start.valueOf(),
        moneyEnd: condition.money === undefined ? '' : condition.money.end.valueOf()
      }
      Object.assign(params, condition)
      store
          .dispatch('financeresource/exportToExcel', params)
          .then(res => {
            toast.success("导出成功")
            let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
            let filename = "资金流水导出模板-" + new Date().getTime();
            /*-------------*/
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = URL.createObjectURL(data);
            link.setAttribute('download', filename + '.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
    }

    const exportDataTT = function () {
      const condition = {...store.getters['financeresource/getCondition']}
      const params = {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc' : '',
        moneyStart: condition.money === undefined ? '' : condition.money.start.valueOf(),
        moneyEnd: condition.money === undefined ? '' : condition.money.end.valueOf()
      }
      Object.assign(params, condition)
      store
          .dispatch('financeresource/exportToExcelT', params)
          .then(res => {
            toast.success("导出成功")
            let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
            let filename = "资金流水导出模板-" + new Date().getTime();
            /*-------------*/
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = URL.createObjectURL(data);
            link.setAttribute('download', filename + '.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
    }

    const toVerification = function () {
      let data = this.checked
      let ids = []
      let totalAmount = 0
      if (data.length < 1) {
        toast.error('至少选择一条资金流水')
        return false
      }
      for (let i = 0; i < data.length; i++) {
        ids.push(data[i].finance_id)
        if (data[0].store_id != data[i].store_id) {
          toast.error('所选店铺团队不一致')
          return false
        }
        if (data[i].status != 3) {
          toast.error('认领状态必须为 待认领')
          return false
        }
        totalAmount += getAbs(data[i].ext.can_receive_amount)
      }
      if (totalAmount <= 0) {
        toast.error('所选资金流水可认领金额绝对值之和必须大于0')
        return false
      }
      //判断是否有关联单据
      axios.post('api/statementreceive/searchByIds', {ids: ids.toString()}).then(res => {
        if(res.data.data.length > 0){
          toast.error('存在已认领的流水，不能核销')
        }else {
          let routerData = this.$router.resolve({
            name: 'apps-statementproxyverification-edit',
            query: {ids: ids, store_id: data[0].store_id, store_name: data[0].store_name},
          })
          window.open(routerData.href, '_blank')
        }
        this.resetChecked()
      })


    }

    // :to="{ name: 'apps-statementreceive-edit', query: { id: data.receive_id, type: 2, readonlys: 0, srmReadonly: 1 } }"

    const toURL = function (data){
      return "/apps/statementreceive/edit?id=" + data.receive_id+"&type=2&readonlys=1&srmReadonly=1"
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = financeresourceUseList()

    return {
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isNumber,
      isEmpty,
      getAbs,
      fromChildren,
      exportData,
      deleteData,
      allotData,
      receiveData,
      cancelRelation,
      onUploaded,
      reload,
      fromChildrenModal,
      createStatementReceive,
      allotDataShowModal,
      // changeSelect,
      exportDataT,
      exportDataTT,
      toVerification,
      cancelRelationItem,
      toURL,
      resetChecked,
    }
  },
  created() {
    const userData = getUserData()
    //财务主管
    if (userData.role_id == 14 || userData.role_id == 12) {
      this.buttonShow = true
    }
    if (userData.role_id == 11 || userData.role_id == 12 || userData.role_id == 14 || userData.role_id == 27) {
      this.verificationShow = true
    }
  }


}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
